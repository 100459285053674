




















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import DeliveryService from '@/common/services/delivery/delivery-service';
import displayMessage from '@/common/functions/display-message';
import { PaginationParams } from '@/common/models/pagination';
import { UserDelivery } from '@/common/models/delivery';
import { getDateRange } from '@/common/functions/dates';

@Component
export default class UsersList extends Vue {
  deliveryService!: DeliveryService;

  isLoading = true;

  isLoadingCsvButton = false;

  search = '';

  selectedDates: string[] = [];

  count = 0;

  itemsPerPage = 10;

  currentPage = 1;

  headers = [
    {
      text: 'ID',
      value: 'id',
      sortable: true,
    },
    {
      text: 'Bestellung am',
      value: 'packaging_at',
      sortable: true,
    },
    {
      text: 'Max. Menge',
      value: 'order.max_quantity',
      sortable: true,
    },
    {
      text: 'Menge',
      value: 'quantity',
      sortable: true,
    },
    {
      text: 'Typ',
      value: 'type',
      sortable: false,
    },
    {
      text: 'Adresse',
      value: 'address',
      sortable: false,
    },
    {
      text: 'Einrichtung',
      value: 'user.profile.institution',
      sortable: true,
    },
    {
      text: 'Username',
      value: 'user.username',
      sortable: true,
    },
    {
      text: 'Tracking ID',
      value: 'tracking_id',
      sortable: false,
    },
  ];

  deliveries: UserDelivery[] = [];

  mounted() {
    this.deliveryService = new DeliveryService();
    this.getDelivery();
  }

  async getDelivery(params?: PaginationParams) {
    this.isLoading = true;

    const { data, message } = await this.deliveryService.getDelivery(params);
    if (data) {
      this.count = data.count;
      this.deliveries = data.results;
    } else {
      displayMessage(message, true, this.$store);
    }

    this.isLoading = false;
  }

  async getCSV() {
    const response = await this.deliveryService.getCSV(this.search.trim(), this.selectedDates);
    return response;
  }

  async downloadCSV() {
    this.isLoadingCsvButton = true;

    const CSVdata = await this.getCSV();
    if (CSVdata.data) {
      const blob = new Blob([CSVdata.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'DeliveryExport.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      displayMessage(CSVdata.message, false, this.$store);
    } else {
      displayMessage(CSVdata.message, true, this.$store);
    }

    this.isLoadingCsvButton = false;
  }

  cleanField(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.getDeliveryBySearch();
    }
    if (event.key === ' ') {
      const { value } = event.target as HTMLInputElement;
      if (!value.trim() || value.endsWith(' ')) {
        event.preventDefault();
      }
    }
  }

  async getDeliveryBySearch() {
    const params = this.getParams();
    this.getDelivery(params);
  }

  getParams(): PaginationParams {
    const dates = getDateRange(this.selectedDates);
    return {
      search: this.search,
      start_date: dates.startDate,
      end_date: dates.endDate,
    };
  }

  onPageChange(page: number) {
    const params = this.getParams();
    params.page = page;
    this.getDelivery(params);
  }

  get dateRangeText() {
    return this.selectedDates;
  }

  get pageCount() {
    const division = this.count / this.itemsPerPage;
    return Math.ceil(division);
  }

  @Watch('search')
  textFieldWithoutSpaces() {
    this.search = this.search.trim();
  }
}
