export const getDateRange = (selectedDates: string[]) => {
  let [startDate, endDate] = selectedDates;
  if (startDate > endDate) {
    [endDate, startDate] = selectedDates;
  }
  if (selectedDates.length === 1) {
    endDate = startDate;
  }
  return { startDate, endDate };
};
