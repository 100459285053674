import { AxiosError } from 'axios';
import ApiService from '../api-service';
import { PaginationParams } from '@/common/models/pagination';
import { DeliveryPagination } from '@/common/models/delivery';

export interface DeliveryResponse {
  statusCode: number;
  data: DeliveryPagination | undefined;
  message: string;
}

export default class DeliveryService extends ApiService {
  async getDelivery(params?: PaginationParams): Promise<DeliveryResponse> {
    try {
      const response = await this.axios.get(`admin/deliveries`, { params });
      return { statusCode: response.status, data: response.data, message: '' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: 'Error' };
      }
      return { statusCode: -1, data: undefined, message: 'Internal Error' };
    }
  }

  async getCSV(
    id: string,
    dates: string[]
  ): Promise<{ statusCode: number; data: string | undefined; message: string }> {
    try {
      let date1 = new Date(0);
      let date2 = new Date(0);

      if (dates[0] !== '') {
        date1 = new Date(dates[0]);
      }

      if (dates[1] !== '') {
        date2 = new Date(dates[1]);
      }

      let startDate = '';
      let endDate = '';

      if (dates.length === 1) {
        [startDate] = dates;
        [endDate] = dates;
      }

      if (date1.getTime() < date2.getTime()) {
        [startDate, endDate] = dates;
      }

      if (date1.getTime() > date2.getTime()) {
        [endDate, startDate] = dates;
      }

      if (date1.getTime() === date2.getTime()) {
        [endDate, startDate] = dates;
      }

      const response = await this.axios.get(
        `admin/deliveries/export?search=${id}&start_date=${startDate}&end_date=${endDate}`
      );
      return { statusCode: response.status, data: response.data, message: 'Datei heruntergeladen' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: 'Page Not Found' };
      }
      return { statusCode: -1, data: undefined, message: 'Internal Error' };
    }
  }
}
